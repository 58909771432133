$(document).on("turbolinks:load", function() {
  const COUNTRY_JAPAN_ID = "1";

  $("body").on("click", ".js-download-file", function(e){
    window.location.href = Routes.show_profile_path({download: true});
  });
  $(".js-select-skill-experiences").on("select2:select", function (evt) {
    let name = $(this).siblings("input").attr("name");
    let element = evt.params.data.element;
    let div = $("#" + element.getAttribute("group"));
    let newElement = $(
      "<div class='form-skill_content_years_experience' id='skills_"+ element.value +"'>\
        <input name='" + name.slice(0, -3) + "_attributes][-"+element.value+"][skill_id]' type='hidden' value='" + element.value + "'/>\
        <div class='skill-name'>"+ element.text + "</div>\
        <div class='years_experience_select'>\
          <select class='form-select input-select input-form input-experience' name='" + name.slice(0, -3) + "_attributes][-"+element.value+"][experience_years]'>\
            <option value=''></option>\
            <option value='under1'>1年未満</option>\
            <option value='under3'>1〜3年</option>\
            <option value='under5'>3〜5年</option>\
            <option value='under10'>5〜10年</option>\
            <option value='over10'>10年以上年</option>\
          </select>\
        </div>\
        <div class='button-close js-delete-skill-experience' data-id='"+ element.value +"'>\
          <img src='/assets/icons/close-595894654fefe0677dd7a18ecdc6d654a24c20fc7e3f7de9f0498b4a034b9e1e.svg'>\
        </div>\
      </div>");
    div.append(newElement);

    $(".input-experience").select2({
      placeholder: '経験年数',
      minimumResultsForSearch: -1,
      width: 'resolve'
    });
    
    $(this).trigger("change");
    div.removeClass("d-none");
  });
  $(".js-select-skill-experiences").on("select2:clear", function (evt) {
    $(".form-skill_content_years_experience").remove();
    $(".js-skills").addClass("d-none");
  });
  $(".js-select-skill-experiences").on("select2:unselecting", function (evt) {
    let id = evt.params.args.data.id;
    if($("#skills_" + id).parents(".js-skills").children(".form-skill_content_years_experience").length == 1){
      $("#skills_" + id).parents(".js-skills").addClass("d-none");
    }
    $("#skills_" + id).remove();
  });
  $("body").on("click", ".js-delete-skill-experience", function(e){
    let id = $(this).data("id").toString();
    let ids = $(".js-select-skill-experiences").val();
    ids.splice(ids.indexOf(id), 1);
    $(".js-select-skill-experiences").val(ids);
    if($(this).parents(".js-skills").children(".form-skill_content_years_experience").length == 1){
      $(this).parents(".js-skills").addClass("d-none");
    }
    this.parentElement.remove();
    $(".js-select-skill-experiences").trigger("change");
  });

  if ($("#user_profile_country_id").length) {
    let $user_profile_country_id = $("#user_profile_country_id");
    let $user_profile_japanese_level = $("#user_profile_japanese_level");

    function enableJapaneseLevel() {
      if ($user_profile_country_id.val() === COUNTRY_JAPAN_ID || $user_profile_country_id.val() === '') {
        $user_profile_japanese_level.parents(".japanese_level-component").addClass("d-none");
        $user_profile_japanese_level.parents(".form-basic-info_content_component").addClass("d-none");
      } else {
        $user_profile_japanese_level.parents(".japanese_level-component").removeClass("d-none");
        $user_profile_japanese_level.parents(".form-basic-info_content_component").removeClass("d-none");
      }
    }

    enableJapaneseLevel()

    $user_profile_country_id.on("change", function (event) {
      enableJapaneseLevel()
    })
  }
  if ($("#user_profile_start_time_specify").length) {
    let $formMonthYear = $(".form-condition_content_month-year");

    if ($(".start_time input[type='radio']:checked").val() === "specify") {
      $formMonthYear.removeClass("d-none");
    } else {
      $formMonthYear.addClass("d-none");
    }

    $(".start_time input").on("change", function () {
      if ($(".start_time input[type='radio']:checked").val() === "specify") {
        $formMonthYear.removeClass("d-none");
      } else {
        $formMonthYear.addClass("d-none");
      }
    });
  }
});
