// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "bootstrap";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "slick-carousel";

// Import js routes
import * as Routes from "../routes";
window.Routes = Routes;

// Import Toastr JS
import toastr from 'toastr'
window.toastr = toastr

// Import common functions
import commonFunctions from "../commons";
window.commonFunctions = commonFunctions;

// Import js modules
import "../packs/modules/select2/index"
import "../packs/modules/profile/application";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
