let commonFunctions = (function () {
  let modules = {};

  modules.TOASTR_SUCCESS = "success"
  modules.TOASTR_ERROR = "error"

  modules.disabledButtonWhenLoading = function (button) {
    $(".js-common-btn").prop("disabled", true);
    button.children(".icon-loading").show();
    button.children(".hide-on-loading").hide();
  }

  modules.enableButtonWhenLoadingDone = function (button) {
    $(".js-common-btn").prop("disabled", false);
    button.children(".icon-loading").hide();
    button.children(".hide-on-loading").show();
  }

  modules.notifyToastr = function (button, type = "success") {
    switch (type) {
      case modules.TOASTR_SUCCESS:
        toastr.success('保存に成功しました。');
        break;
      case modules.TOASTR_ERROR:
        toastr.error('保存に失敗しました。');
        break;
    }
    modules.enableButtonWhenLoadingDone(button);
  }

  modules.showMessageValidate = function (messageList) {
    modules.clearMessageValidate();
    $.each(messageList, function (key, value) {
      if (key.includes('.')) {
        const attribute = key.split('.');
        $($('.' + attribute[0])[attribute[1]]).addClass('validate-error');
        $($('.error-message[data-field=' + attribute[0] + ']')[attribute[1]]).append(value);
      } else {
        $('input[data-field=' + key + ']').addClass('validate-error');
        $('textarea[data-field=' + key + ']').addClass('validate-error');
        $('.error-message[data-field=' + key + ']').append(value)
      }
    });
  };

  modules.clearMessageValidate = function () {
    $('.error-message').text('');
    $('.validate-error').removeClass('validate-error');
  };

  modules.formatState = function (state) {
    if (!state.id) { return state.text; }
    var $state = $(
      `<span class="d-flex"><div class="img-flag"></div>  ${state.text} </span>`
    );
    return $state;
  };

  $(document).on("turbolinks:load", function () {
    $(".slider").slick({
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      prevArrow: "<div class='icon-arrow-slider prev-slider slick-prev'><div>",
      nextArrow: "<div class='icon-arrow-slider next-slider slick-next'></div>",
      variableWidth: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 576,
          arrows: false,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  
    $(".slider-agents").slick({
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      prevArrow: "<div class='icon-arrow-slider prev-slider slick-prev'><div>",
      nextArrow: "<div class='icon-arrow-slider next-slider slick-next'></div>",
      variableWidth: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            infinite: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });

    $(".js-skillsheet-file-input").on("change", function(e){
      if (e.target.files.length < 1) return;
      let inputId = document.getElementById('file-upload');
      let $this = $(this);
      let name = e.target.files[0].name;
      let allowedExtensions = /(\.pdf|\.doc|\.docx|\.xlsx)$/i;
      if(!allowedExtensions.exec(name)){
        name = "";
        inputId.value = "";
        toastr.error('アップロードファイル形式が正しくありません。');
        return false;
      }
      if(e.target.files[0].size/1024/1024 > 5) {
        inputId.value = "";
        toastr.error('アップロードファイル容量の５MB以下が必須です。');
        return false;
      }
      let base_name = name.substr(0, name.lastIndexOf("."));
      let ext_name = name.substr(name.lastIndexOf("."));
      let $container = $this.closest(".js-upload-skillsheet").find(".js-file-info");
      $container.find(".js-file-base-name").text(base_name);
      $container.find(".js-file-extention").text(ext_name);
      if ($this.data("type") === "auto-submit") {
        $this.parent("form").submit();
      }
    });
  });
  return modules;
}(window.jQuery, window, document));

export default commonFunctions;
