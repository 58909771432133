import "select2";

$(document).on("turbolinks:load", function () {
  $('.multiple_select').select2({
    placeholder: 'Java',
    allowClear: true,
    templateResult: commonFunctions.formatState,
    width: 'resolve',
  });
  $(".input-select-area").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-area_id").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-japanese_level").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-english_level").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-commute_time").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-year").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-month").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-language").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-language-level").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-country").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-country_id").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-place_residence").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-select-nearest_station").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $(".input-experience").select2({
    placeholder: '経験年数',
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $("#user_profile_project_destination_area").select2({
    placeholder: '選択',
    allowClear: true,
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
  $("#user_profile_user_attributes_desired_skill_ids").select2({
    allowClear: false,
    placeholder: 'Java',
    minimumResultsForSearch: -1,
    width: 'resolve'
  });
});
